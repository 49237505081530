<template>
  <div id="add-blog">
    <h1 class="showtit">添加文章</h1>
    <hr>
    <form>
      <a-input style="margin-top: 15px" size="large" addon-before="标题:" v-model="blog.posts_name"/>

      <a-input style="margin-top: 15px" size="large" addon-before="权重:" placeholder="权重0-100" v-model="blog.index"/>

      <div style="width: 100%;display: flex;align-items: center;margin-top: 15px">
        <div style="width: 4%;font-size: 17px;text-align:center;">
          模块:
        </div>
        <a-select v-model="blog.type" style="width: 100%">
          <a-select-option :value="0">更新介绍</a-select-option>
          <a-select-option :value="1">帮助中心</a-select-option>
        </a-select>
      </div>


      <label style="margin-top: 60px">文章内容</label>
      <!--      <mavon-editor v-model='blog.preview' :ishljs="true" @change='updateDoc'></mavon-editor>-->
      <we style="width: 100%" v-model="blog.posts_content" :isClear="false"></we>

      <button class="add cssca" type="button" style=" background: #e83202;" @click.prevent="test">添加文章</button>


    </form>


    <div id="preview">
      <h2>文章预览</h2>
      <hr>
      <p>文章标题：{{ blog.posts_name }}</p>
      <!--      <el-tag v-for="(item,index) in blog.categories" :key="index">{{item}}</el-tag>-->
      <hr class="ad" noshade color="#CCCCCC">
      <p style="word-break: break-all" v-html="blog.posts_content"></p>


    </div>
  </div>
</template>

<script>

import we from '@/components/WANG'

export default {
  components: {
    we
  },

  name: "AddHelperOrUpdate",
  data() {
    return {

      blog: {
        posts_name: "",
        posts_content: "",
        index: "",
        type: null,
      },


    }
  },
  methods: {

    test() {
      this.$axios.post('/Admin/Api/Helps/addHelps', {
        ...this.blog
      }).then(value => {
        this.openType('success', value.data.code, value.data.msg)
        setTimeout(() => {
          this.$router.push('/BackEnd/HelperCenter')
        }, 500)
      }).catch(err => this.openType('error', err.msg, err.data)
      )
    },

    openType(type, code, msg) {
      this.$notification[type]({
        message: 'code: ' + code,
        description:
        msg,
      });
    },

  },

  beforeDestroy() {

  },

  created() {
    const metas = document.getElementsByTagName("meta");
    metas[4].content = 'width=device-width,initial-scale=0.38'
  }
}
</script>

<style scoped>

#border-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.cssca {
  border: 0;
  border-radius: 10px;
  background: #2ec4b6;
  /* 文字变大写 */
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 15px 30px;
  outline: none;
  position: relative;
  /* 添加动画，对元素的 border-radius 添加 3s 的延迟效果 */
  transition: border-radius 3s;
  -webkit-transition: border-radius 3s;
}

.cssca:hover {
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
}


#draw-border {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}


#add-blog * {
  box-sizing: border-box;
}

#add-blog {
  margin: 20px auto;
  max-width: 95%;
  padding: 20px;
}

label {
  display: block;
  margin-top: 20px;
}

input[type="text"], textarea {
  display: block;
  width: 100%;
  padding: 8px;
}

input[type="checkbox"] {

  width: 20px;
  margin-right: 50px;
  margin-top: 20px;
}

textarea {
  height: 200px;
}

#checkboxes label {
  display: inline-block;
  margin-top: 0;
}

#checkboxes input {
  display: inline-block;
  margin-left: 10px;
}

.add {
  margin-top: 10px;
  font-size: 18px;
  width: 100px;
  padding: 10px;
  height: auto;
  border-radius: 15px;

}

#preview {
  border-radius: 19px;
  padding: 10px 20px;
  border: 1px dotted #ccc;
  margin: 15px 0;
  box-shadow: 2.5px 3px 5px 3px rgba(43, 44, 31, 0.2);
}

h2 {
  margin-top: 10px;
}

.ad {
  margin: 5px 0;
}

</style>
